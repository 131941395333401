<template>
	<div>
		<FormWeb :id="id" @close="close" />
	</div>
</template>

<script>
import { GET_FORM_WEB_DETAIL } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import { RESET_FILTERS_FORM_LIST } from "../store/mutation-types"
const { mapActions, mapMutations, mapState } = createNamespacedHelpers("forms")
const { mapActions: orderTypeActions } = createNamespacedHelpers("typeOrder")
export default {
	name: "FormWebDetail",
	components: {
		FormWeb: () => import("../components/FormWeb.vue"),
	},
	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},
	computed: {
		...mapState(["recentFilters"]),
	},
	async created() {
		await Promise.all([this.getFormWebDetail(), this.getOrderTypes()])
	},
	beforeDestroy() {
		this.RESET_FILTERS_FORM_LIST()
	},
	methods: {
		...mapActions({ GET_FORM_WEB_DETAIL }),
		...orderTypeActions({ GET_TYPES_ORDER }),
		...mapMutations({ RESET_FILTERS_FORM_LIST }),
		async getFormWebDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_FORM_WEB_DETAIL(params)
		},
		async getOrderTypes() {
			const params = {
				data: {},
				notLoading: true,
			}
			await this.GET_TYPES_ORDER(params)
		},
		close() {
			this.$router.push({
				path: "forms/list",
			})
		},
	},
}
</script>

<style></style>
